// extracted by mini-css-extract-plugin
export var lbContainerOuter = "F_vP";
export var lbContainerInner = "F_vQ";
export var movingForwards = "F_vR";
export var movingForwardsOther = "F_vS";
export var movingBackwards = "F_vT";
export var movingBackwardsOther = "F_vV";
export var lbImage = "F_vW";
export var lbOtherImage = "F_vX";
export var prevButton = "F_vY";
export var nextButton = "F_vZ";
export var closing = "F_v0";
export var appear = "F_v1";